import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/tpa-settings/react';

import { SocialGroupStore } from 'store/SocialGroupsStore';

import { ThemeProvider } from 'common/context/theme';
import { GroupList } from 'common/components/GroupList/GroupList';
import { Router } from 'common/router';
import { GroupsAppProvider } from 'Groups/contexts/GroupsAppProvider';

import { GroupsWidgetWithStyles } from 'Groups/Widget/App/types';
import { GroupsSection } from 'Groups/Widget/Groups/GroupsSection';
import { useShadowStyles } from 'common/hooks/useShadowStyles';
import { ControllerProvider } from 'common/context/controller';

import settingsParams from 'Groups/settingsParams';
import stylesParams from 'Groups/stylesParams';

import { classes } from './WidgetRoot.st.css';

// TODO: refactor for GroupWidgetWithStyles interface
export const WidgetRoot: React.FC<GroupsWidgetWithStyles> = (props) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const styles = useStyles();
  const shadowStyles = useShadowStyles();

  return (
    <div className={classes.root} ref={shadowStyles.onRefChange}>
      <GroupsAppProvider {...props}>
        <SocialGroupStore state={(props as any).store}>
          <ControllerProvider {...(props as any)}>
            <Router>
              <ThemeProvider
                host={props.host}
                buttonType={settings.get(settingsParams.buttonType)}
                buttonBorderRadius={styles.get(stylesParams.buttonBorderRadius)}
              >
                <GroupsSection>
                  {({ openCreateGroupModal }) => (
                    <GroupList
                      onCreateGroupClick={openCreateGroupModal}
                      mobile={isMobile}
                    />
                  )}
                </GroupsSection>
              </ThemeProvider>
            </Router>
          </ControllerProvider>
        </SocialGroupStore>
      </GroupsAppProvider>
    </div>
  );
};

WidgetRoot.displayName = 'WidgetRoot';
